export const secAry = [
  { label: '0秒', value: 0 },
  { label: '1秒', value: 1 },
  { label: '2秒', value: 2 },
  { label: '3秒', value: 3 },
  { label: '4秒', value: 4 },
  { label: '5秒', value: 5 },
  { label: '6秒', value: 6 },
  { label: '7秒', value: 7 },
  { label: '8秒', value: 8 },
  { label: '9秒', value: 9 },
  { label: '10秒', value: 10 },
  { label: '11秒', value: 11 },
  { label: '12秒', value: 12 },
  { label: '13秒', value: 13 },
  { label: '14秒', value: 14 },
  { label: '15秒', value: 15 },
  { label: '16秒', value: 16 },
  { label: '17秒', value: 17 },
  { label: '18秒', value: 18 },
  { label: '19秒', value: 19 },
  { label: '20秒', value: 20 },
  { label: '21秒', value: 21 },
  { label: '22秒', value: 22 },
  { label: '23秒', value: 23 },
  { label: '24秒', value: 24 },
  { label: '25秒', value: 25 },
  { label: '26秒', value: 26 },
  { label: '27秒', value: 27 },
  { label: '28秒', value: 28 },
  { label: '29秒', value: 29 },
  { label: '30秒', value: 30 },
  { label: '31秒', value: 31 },
  { label: '32秒', value: 32 },
  { label: '33秒', value: 33 },
  { label: '34秒', value: 34 },
  { label: '35秒', value: 35 },
  { label: '36秒', value: 36 },
  { label: '37秒', value: 37 },
  { label: '38秒', value: 38 },
  { label: '39秒', value: 39 },
  { label: '40秒', value: 40 },
  { label: '41秒', value: 41 },
  { label: '42秒', value: 42 },
  { label: '43秒', value: 43 },
  { label: '44秒', value: 44 },
  { label: '45秒', value: 45 },
  { label: '46秒', value: 46 },
  { label: '47秒', value: 47 },
  { label: '48秒', value: 48 },
  { label: '49秒', value: 49 },
  { label: '50秒', value: 50 },
  { label: '51秒', value: 51 },
  { label: '52秒', value: 52 },
  { label: '53秒', value: 53 },
  { label: '54秒', value: 54 },
  { label: '55秒', value: 55 },
  { label: '56秒', value: 56 },
  { label: '57秒', value: 57 },
  { label: '58秒', value: 58 },
  { label: '59秒', value: 59 },
];
