import styles from './index.module.less';
import EMPTY from '../../assets/img/empty.png';

interface IProps {
  title: string;
}
const Empty: React.FC<IProps> = (props: IProps) => {
  const { title } = props;
  return (
    <div className={styles.wrap}>
      <img src={EMPTY} className={styles.img} />
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default Empty;
