export const getGregorianCalendar = (
  year: number,
  month: number,
  day: number | undefined,
  hour: number | undefined,
  minutes: number | undefined,
  seconds: number | undefined,
) => {
  return new Date(year, month, day, hour, minutes, seconds);
};

export const cloneDate = (date: string | number) => {
  return new Date(+date);
};

// 获取当月天数
export function getDaysInMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export const setMonth = (
  date: {
    setDate: (arg0: number) => void;
    getDate: () => number;
    getFullYear: () => number;
    setMonth: (arg0: any) => void;
  },
  month: number,
) => {
  date.setDate(
    Math.min(
      date.getDate(),
      getDaysInMonth(new Date(date.getFullYear(), month)),
    ),
  );
  date.setMonth(month);
};

// 补齐格式
export const pad = (n: number) => {
  return n < 10 ? `0${n}` : n;
};
