import { omit } from 'lodash-es';
import request from './request';

const removeFnFromProps = (props: any, fnList: any) => {
  return omit(props, fnList);
};
const getQueryVariable = (variable: string) => {
  const query = window.location.href.split('?')[1];
  if (query) {
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
  }
  return '';
};

const formatNumber = (number: number, n: number = 2) => {
  return Math.round(number * Math.pow(10, n)) / Math.pow(10, n);
};

const is_weixn = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  const match = ua.match(/MicroMessenger/i);
  if (match === null) {
    return false;
  }
  if (match.includes('micromessenger')) {
    return true;
  }
  return false;
};
const getStorage = (key: string) => {
  return localStorage.getItem(key);
};
const setStorage = (key: string, token: string) => {
  return localStorage.setItem(key, token);
};
const removeStorage = (key: string) => {
  localStorage.removeItem(key);
};

export {
  formatNumber,
  getQueryVariable,
  getStorage,
  is_weixn,
  removeFnFromProps,
  removeStorage,
  request,
  setStorage,
};
