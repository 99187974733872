import H5Config from '@/config';
import zauth from '@zelos/oauth';
import Axios from 'axios';
import qs from 'qs';
import { Toast } from 'zarm';
import { getStorage } from '.';

const baseURL = H5Config.getDerivedAppUrl('gateway');
const request = zauth
  .extend(Axios, {
    redirect_uri: `${window.location.protocol}//${window.location.host}`,
    forward: zauth.CLIENT_FORWARD.H5,
    client_id: H5Config.authClientId,
    client_secret: H5Config.authClientSecret,
    host: H5Config.getDerivedAppDomain('auth'),
  })
  .create({
    baseURL,
    timeout: 1000 * 60,
  });

request.interceptors.response.use((response) => {
  const { success, isSuccess, data, message: msg } = response.data;
  if (success || isSuccess) {
    return { data, success, isSuccess } as any;
  }

  const { messageFree } = response.config as any;

  if (!messageFree) {
    Toast.show(msg);
  }

  return Promise.reject(response.data);
});

export default request;

export const getRefreshTokenFunc = (): Promise<any> => {
  return request.post(
    '/oauth/token',
    qs.stringify({
      client_id: H5Config.authClientId,
      grant_type: 'refresh_token',
      client_secret: H5Config.authClientSecret,
      refresh_token: getStorage('refresh_token'),
      forward: zauth.CLIENT_FORWARD.H5,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};
